$('footer #popularLinksList').on('hidden.bs.collapse', function () {
  console.log('hide');
    $('footer .js-contract').hide();
    $('footer .js-expand').show();
});
$('footer #popularLinksList').on('show.bs.collapse', function () {
  console.log('show');
    $('footer .js-contract').show();
    $('footer .js-expand').hide();
})
